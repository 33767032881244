import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Link,
  Checkbox,
  Container,
  Typography,
  CssBaseline,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import { Heading } from '../../components/Text'
import { FormInput } from '../../components/FormInput'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton } from '../../components/Button/GradientButton'
import _ from 'lodash'
import { post } from '../../services/request'
import { useStoreActions } from '../../store/store.hooks'
import { EXTERNAL_FILES } from '../../constants'
import { validateLocales } from '../../helpers/languageFormatter'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerBlock: {
    paddingBottom: 5,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loginContainer: {
    display: 'flex',
    paddingBottom: '60px',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  loginForm: {
    marginTop: '50px',
  },
  loginInput: {
    marginBottom: '30px',
  },
  loginButton: {
    height: '50px',
  },
  iconContainer: {
    width: 45,
    height: 45,
    borderWidth: 2,
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: 'grey',
  },
  tickIcon: {
    fontSize: 24,
    color: '#3CAAF0',
    marginTop: 8,
  },
  registerContainer: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  acceptTosWarning: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  formLabel: {
    color: '#6473AF',
    fontWeight: 500,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    marginLeft: '15px',
  },
  accountSelect: {
    width: '100%',
  },
  accountWarning: {
    textAlign: 'left',
    marginLeft: '15px',
    width: '100%',
  },
  selectAccountWarning: {
    textAlign: 'left',
    marginTop: '0',
    marginLeft: '15px',
    width: '100%',
  },
  fullWidthBox: {
    width: '100%',
  },
  usageTypeRadioGroup: {
    marginLeft: '15px',
    marginTop: '0.5rem',
  },
  voucherCodeInput: {
    marginTop: '0.5rem',
  },
  voucherValidateButton: {
    marginTop: '0.5rem',
  },
}))

export const Registration = () => {
  const { i18n, t } = useTranslation('registration')
  const language = validateLocales(i18n.language)
  const linkedFiles = (EXTERNAL_FILES as any)[language]

  const {
    watch,
    formState: { errors },
    control,
    register,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      usageType: '',
      voucherCode: '',
      addToNewsletter: false,
      tosChecked: false,
    },
  })

  const {
    auth: { setToken },
  } = useStoreActions((actions) => actions)
  const {
    layout: { setNotification },
  } = useStoreActions((actions) => actions)
  const [usageTypeError, setUsageTypeError] = useState(false)
  const [hasValidVoucher, setHasValidVoucher] = useState(true)

  const onSubmit = async (values: any) => {
    console.log('Submitting:', { values, errors })
    setUsageTypeError(false)
    setHasValidVoucher(true)

    if (values.tosChecked !== true) {
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error'),
      })
      return
    }

    if (!values.usageType) {
      setUsageTypeError(true)
      return
    }

    values.voucherCode = values.voucherCode && values.voucherCode.trim()

    if (values.usageType === 'partner-account' && !values.voucherCode) {
      return
    }

    // Validate the voucher first, if we have one
    if (values.usageType === 'partner-account' && values.voucherCode) {
      const result = await post<any>('/public/vouchers/verify', {
        voucher: values.voucherCode,
      })

      if (result.id && result.accountId) {
        setHasValidVoucher(true)
      } else {
        setHasValidVoucher(false)
        return
      }
    }

    try {
      const params = {
        ..._.pick(values, 'email', 'password', 'name', 'addToNewsletter'),
        userTypeId: 2,
        language: i18n.language,
        voucherCode:
          values.usageType === 'partner-account' ? values.voucherCode : null,
        isGeneralPublic: values.usageType === 'general-public',
      }

      params.email = params.email && params.email.trim()

      const { token } = await post<{ token: string }>('/auth/register', params)
      window.localStorage.token = token
      setToken(token)
      const dataLayer = (window as any).dataLayer || []
      dataLayer.push({
        event: 'Regisztráció',
        eventCategory: 'Regisztráció',
        eventAction: 'Form elküldve',
        eventLabel: 'Button',
      })
    } catch (err) {
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error'),
      })
    }
    // App.tsx will take care of routing
  }

  const classes = useStyles()
  const passwordMismatch = t('passwordMismatch')
  const emailMismatch = t('emailMismatch')

  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    required: t<string>('missingField'),
  })
  const { ref: emailInputRef, ...emailInputProps } = register('email', {
    required: t<string>('missingField'),
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: t<string>('invalidEmail'),
    },
  })
  const { ref: confirmEmailInputRef, ...confirmEmailInputProps } = register(
    'confirmEmail',
    {
      required: t<string>('missingField'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: t<string>('invalidEmail'),
      },
      validate: (value: string) => {
        return value === watch('email') || emailMismatch
      },
    }
  )
  const { ref: passwordInputRef, ...passwordInputProps } = register(
    'password',
    {
      required: t<string>('missingField'),
      pattern: {
        value: /^\S+$/i,
        message: t<string>('invalidPassword'),
      },
    }
  )
  const { ref: confirmPasswordInputRef, ...confirmPasswordInputProps } =
    register('confirmPassword', {
      required: t<string>('missingField'),
      pattern: {
        value: /^\S+$/i,
        message: t<string>('invalidPassword'),
      },
      validate: (value: string) =>
        value === watch('password') || passwordMismatch,
    })
  const { ref: addToNewsletterInputRef, ...addToNewsletterInputProps } =
    register('addToNewsletter', {
      required: false,
    })
  const { ref: tosCheckedInputRef, ...tosCheckedInputProps } = register(
    'tosChecked',
    {
      required: t<string>('missingField'),
    }
  )

  const { ref: voucherCodeInputRef, ...voucherCodeInputProps } =
    register('voucherCode')

  useEffect(() => {
    const usageType = watch('usageType')
    if (usageType === 'general-public' || usageType === 'partner-account') {
      setUsageTypeError(false)
    }
  }, [watch('usageType')])

  return (
    <div className={classes.registerContainer}>
      <div className={classes.headerBlock}>
        <NavigateBack />
        <Heading>{t<string>('heading')}</Heading>
      </div>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Grid className={classes.loginContainer}>
          <Container maxWidth="sm" className={classes.loginForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                inputName="name"
                error={!!errors.name}
                value={watch('name')}
                label={t<string>('nameLabel')}
                clearError={() => clearErrors('name')}
                placeholder={t<string>('namePlaceholder')}
                className={classes.loginInput}
                errorMessage={String(errors?.name?.message ?? '')}
                inputRef={nameInputRef}
                {...nameInputProps}
              />
              <FormInput
                inputName="email"
                error={!!errors.email}
                value={watch('email')}
                label={t<string>('emailLabel')}
                clearError={() => clearErrors(['email', 'confirmEmail'])}
                placeholder={t<string>('emailPlaceholder')}
                className={classes.loginInput}
                errorMessage={String(errors?.email?.message ?? '')}
                inputRef={emailInputRef}
                {...emailInputProps}
              />
              <FormInput
                inputName="confirmEmail"
                className={classes.loginInput}
                value={watch('confirmEmail')}
                error={!!errors.confirmEmail}
                label={t<string>('confirmEmailLabel')}
                placeholder={t<string>('confirmEmailPlaceholder')}
                clearError={() => clearErrors(['email', 'confirmEmail'])}
                errorMessage={String(errors?.confirmEmail ?? '')}
                inputRef={confirmEmailInputRef}
                {...confirmEmailInputProps}
              />
              <FormInput
                type="password"
                inputName="password"
                value={watch('password')}
                label={t<string>('passwordLabel')}
                clearError={() => clearErrors(['password', 'confirmPassword'])}
                error={!!errors.password}
                placeholder={t<string>('passwordPlaceholder')}
                className={classes.loginInput}
                errorMessage={String(errors?.password?.message ?? '')}
                inputRef={passwordInputRef}
                {...passwordInputProps}
              />
              <FormInput
                type="password"
                inputName="confirmPassword"
                className={classes.loginInput}
                value={watch('confirmPassword')}
                error={!!errors.confirmPassword}
                label={t<string>('confirmPasswordLabel')}
                placeholder={t<string>('confirmPasswordPlaceholder')}
                clearError={() => clearErrors(['password', 'confirmPassword'])}
                errorMessage={String(errors?.confirmPassword?.message ?? '')}
                inputRef={confirmPasswordInputRef}
                {...confirmPasswordInputProps}
              />

              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                className={classes.fullWidthBox}
                mb={4}
              >
                <FormLabel className={classes.formLabel}>
                  {t('chooseAccount')}
                </FormLabel>
                <Controller
                  name="usageType"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <RadioGroup
                        className={classes.usageTypeRadioGroup}
                        {...field}
                        onChange={(event, value) => field.onChange(value)}
                        value={field.value}
                      >
                        <FormControlLabel
                          value="general-public"
                          control={<Radio />}
                          label={t('registerAsGeneralPublic')}
                        />
                        <FormControlLabel
                          value="partner-account"
                          control={<Radio />}
                          label={t('registerAsPartnerAccount')}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />

                {watch('usageType') === 'partner-account' && (
                  <>
                    <FormInput
                      inputName="voucherCode"
                      inputLabelClassName={classes.voucherCodeInput}
                      value={watch('voucherCode')}
                      label={t<string>('voucherInputLabel')}
                      clearError={() => clearErrors(['voucherCode'])}
                      error={!!errors.voucherCode}
                      placeholder={t<string>('voucherPlaceholder')}
                      errorMessage={String(errors?.voucherCode?.message ?? '')}
                      inputRef={voucherCodeInputRef}
                      {...voucherCodeInputProps}
                    />
                  </>
                )}

                {usageTypeError && (
                  <FormHelperText
                    error
                    className={classes.selectAccountWarning}
                  >
                    {t('selectAccount')}
                  </FormHelperText>
                )}

                {!hasValidVoucher && (
                  <>
                    <FormHelperText error className={classes.accountWarning}>
                      {t('invalidVoucherCodeLine1')}
                    </FormHelperText>
                    <FormHelperText error className={classes.accountWarning}>
                      {t('invalidVoucherCodeLine2')}
                    </FormHelperText>
                  </>
                )}

                {watch('usageType') === 'partner-account' &&
                  !watch('voucherCode') && (
                    <FormHelperText error className={classes.accountWarning}>
                      {t('missingVoucherCode')}
                    </FormHelperText>
                  )}
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={2}
              >
                <Controller
                  render={() => (
                    <Checkbox
                      icon={<div className={classes.iconContainer} />}
                      checkedIcon={
                        <div className={classes.iconContainer}>
                          <Check className={classes.tickIcon} />
                        </div>
                      }
                      inputRef={addToNewsletterInputRef}
                      {...addToNewsletterInputProps}
                    />
                  )}
                  control={control}
                  defaultValue={false}
                  name="addToNewsletter"
                />
                <Typography align="left">{t<string>('newsletter')}</Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={2}
              >
                <Controller
                  render={() => (
                    <Checkbox
                      icon={<div className={classes.iconContainer} />}
                      checkedIcon={
                        <div className={classes.iconContainer}>
                          <Check className={classes.tickIcon} />
                        </div>
                      }
                      inputRef={tosCheckedInputRef}
                      {...tosCheckedInputProps}
                    />
                  )}
                  control={control}
                  defaultValue={false}
                  name="tosChecked"
                />
                <Typography align="left">
                  {t<string>('termsAndConditions')}
                  <Link
                    href={linkedFiles.TERMS_AND_CONDITIONS}
                    color="error"
                    target="_blank"
                  >
                    {t<string>('tocName')}
                  </Link>
                  {t<string>('and')}
                  <Link
                    href={linkedFiles.PRIVACY_POLICY}
                    color="error"
                    target="_blank"
                  >
                    {t<string>('privacyPolicy')}
                  </Link>
                  {t<string>('adult')}
                </Typography>
              </Box>
              {errors.tosChecked && (
                <FormHelperText error className={classes.acceptTosWarning}>
                  {t<string>('acceptTos')}
                </FormHelperText>
              )}
              <GradientButton type={'submit'} className={classes.loginButton}>
                {t<string>('registrationButton')}
              </GradientButton>
            </form>
          </Container>
        </Grid>
      </Container>
    </div>
  )
}
